$font-weight-ultra: 900;
$font-weight-black: 800;
$font-weight-bold: 700;
$font-weight-book: 600;
$font-weight-medium: 500;
$font-weight-regular: 400;
$font-weight-thin: 300;
$font-weight-light: 200;
$font-weight-extralight: 100;

@mixin font-face($name, $path, $weight: null, $style: null) {
  @font-face {
    font-family: quote($name);
    font-style: $style;
    font-weight: $weight;
    src: url('./fonts/#{$path}.eot');
    src: url('./fonts/#{$path}.eot?#iefix') format('embedded-opentype'),
      url('./fonts/#{$path}.woff2') format('woff2'), url('./fonts/#{$path}.woff') format('woff'),
      url('./fonts/#{$path}.ttf') format('truetype'),
      url('./fonts/#{$path}.svg#geomanistregular') format('svg');
  }
}

@include font-face(
  'Geomanist',
  'Geomanist-Ultra-Webfont/geomanist-ultra-webfont',
  $font-weight-ultra,
  normal
);

@include font-face(
  'Geomanist',
  'Geomanist-Black-Webfont/geomanist-black-webfont',
  $font-weight-black,
  normal
);

@include font-face(
  'Geomanist',
  'Geomanist-Bold-Webfont/geomanist-bold-webfont',
  $font-weight-bold,
  normal
);

@include font-face(
  'Geomanist',
  'Geomanist-Book-Webfont/geomanist-book-webfont',
  $font-weight-book,
  normal
);

@include font-face(
  'Geomanist',
  'Geomanist-Medium-Webfont/geomanist-medium-webfont',
  $font-weight-medium,
  normal
);

@include font-face(
  'Geomanist',
  'Geomanist-Regular-Webfont/geomanist-regular-webfont',
  $font-weight-regular,
  normal
);

@include font-face(
  'Geomanist',
  'Geomanist-Thin-Webfont/geomanist-thin-webfont',
  $font-weight-thin,
  normal
);

@include font-face(
  'Geomanist',
  'Geomanist-Light-Webfont/geomanist-light-webfont',
  $font-weight-light,
  normal
);

@include font-face(
  'Geomanist',
  'Geomanist-ExtraLight-Webfont/geomanist-extralight-webfont',
  $font-weight-extralight,
  normal
);
